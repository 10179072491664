




































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import { deepGet } from '@/utils/ObjectHelpers';
import { Data } from '@/utils/types/WidgetData';

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonGeneric extends VueBaseActionButtonWidget {
  @Prop({
    required: false,
    default: null,
  })
  private readonly genericActionResult!: Record<string, Data[]>;

  @Prop({
    required: false,
    default: null,
  })
  private readonly icon!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly label!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly route!: string;

  private parseRoute: string | null = '';

  private dataSourceEmpty = true;

  private get text(): string {
    return `${this.$t(this.label)}`;
  }

  private get isDisplayed(): boolean {
    return this.actionType === this.ActionButtonType.GENERIC;
  }

  created(): void {
    this.buttonsDisplay();
    this.setUrlText();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  private setUrlText(): void {
    let localRoute = this.route;
    if (this.genericActionResult !== null && this.genericActionResult !== {}) {
      const route = this.$t(this.route) as string;
      const matches = route.match(/({{[a-zA-Z-_.[0-9\]*]+}})/gs);
      if (matches) {
        matches.forEach((m) => {
          const removeOpenBrackets = m.replaceAll('{', '')
            .trim();
          const removeCloseBrackets = removeOpenBrackets.replaceAll('}', '')
            .trim();

          let newValue = deepGet(this.genericActionResult, removeCloseBrackets);
          if (!newValue) {
            newValue = deepGet(this.genericActionResult[0], removeCloseBrackets);
          }

          if (newValue) {
            this.dataSourceEmpty = false;
            localRoute = localRoute.replaceAll(m, newValue);
          }
        });
      }
    }
    this.parseRoute = localRoute;
  }

  private handleBaseUrl(): void {
    if (this.parseRoute) {
      const buttonRoute = this.parseRoute.toString();
      let external = buttonRoute.startsWith('//');
      if (external) {
        this.logStats(StatLoggerCategories.LINK, StatLoggerActions.ADD, buttonRoute);
        window.open(buttonRoute, '_blank');
      } else {
        try {
          const url = new URL(buttonRoute);
          external = !!url.protocol;
          if (external) {
            this.logStats(StatLoggerCategories.LINK, StatLoggerActions.ADD, buttonRoute);
            window.open(buttonRoute, '_blank');
          }
        } catch {
          let r = buttonRoute;
          const matches = buttonRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
          if (matches) {
            matches.forEach((m) => {
              const prop = m.replaceAll('%', '')
                .trim();
              if (this.$route.params[prop]) {
                r = r.replaceAll(m, this.$route.params[prop]);
              }
            });
          }
          this.logStats(StatLoggerCategories.LINK, StatLoggerActions.ADD, r);
          this.$router.push(r);
        }
      }
    }
  }
}
